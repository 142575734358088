import type { NextPage } from "next";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { Layout } from "../shared/components/Layout";
import HomePage from "../components/Home/index";
import DocHead from "../components/Home/DocHead";
import { getConfig, setPlanGroups } from "../slices/onboarding.slice";
import { shouldRedirectToNewFlow } from "../shared/utils/helper.utils";
import { useAutoLogin } from "./../shared/hooks";
import { authenticatedAxios } from "../shared/setups/axios";

const baseURL = process.env.NEXT_PUBLIC_BASE_URL;

const Home: NextPage = ({ plans }: any) => {
  const [plansList, setPlansList] = useState<any>(plans);

  const dispatch = useDispatch<any>();
  const utm = useSelector((state: any) => state.utmReducer);
  const newFlow = shouldRedirectToNewFlow(utm);
  const { isConfigLoading, config, groupId } = useSelector(
    (state: any) => state.onboarding
  );
  const { userInfo } = useSelector((state: any) => state.auth);

  useAutoLogin();

  useEffect(() => {
    (async () => {
      const res = await authenticatedAxios
        .get(
          newFlow
            ? `${baseURL}/api/v2/plan-purchase/plan-groups`
            : `${baseURL}/api/v1/basePlan/all?pageNo=${0}&pageSize=${20}`
        )
        .catch((error: any) => {
          console.log("Error", error);
        });

      setPlansList(res?.data?.data || null);

      if (newFlow) dispatch(setPlanGroups(res?.data?.data?.planGroups));
    })();
  }, []);

  useEffect(() => {
    if (userInfo?.userId)
      if (!isConfigLoading || !Object.keys(config)?.length) {
        dispatch(getConfig({ groupId }));
        return;
      }
  }, []);

  return (
    <Layout>
      <DocHead />
      <HomePage plans={plansList} />
    </Layout>
  );
};

export const getStaticProps = async ({ locale = "en" }: any) => {
  const content = await serverSideTranslations(locale, ["common", "header"]);

  const res = await authenticatedAxios
    .get(`${baseURL}/api/v1/basePlan/all?pageNo=${0}&pageSize=${15}`)
    .catch((error: any) => {
      console.log("Error", error);
    });
  return {
    props: {
      plans: res?.data?.data || null,
      ...content,
    },
  };
};

export default Home;
